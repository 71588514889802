import Vue from 'vue';
import VueI18n from 'vue-i18n';
import api from '../services/backendApi';
import locales from '../locales/languages.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
	locale: navigator.language || 'nl-BE',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'nl-BE',
	messages: locales,
	sync: true
});

export async function loadFromLocalise() {
	const response = await api.get('translations/getAll', {});
	const translations = response.data;
	Object.keys(translations).forEach((key) => {
		i18n.setLocaleMessage(key, mergeDeep(translations[key], locales[key]));
	});
}

export default i18n;

export function isObject(item) {
	return (item && typeof item === 'object');
}


export function mergeDeep(target, source) {
	if (isObject(target) && isObject(source)) {
		Object.keys(source).forEach((key) => {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				if (source[key] !== '') {
					Object.assign(target, { [key]: source[key] });
				}
			}
		});
	}

	return target;
}
