var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dyn-comp input--dynamic" },
    [
      _c(_vm.component, {
        tag: "component",
        attrs: { listItems: _vm.listItems, selectedItems: _vm.selectedItems },
        on: { selectedChanged: _vm.onSelectedChanged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }