var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-autocomplete", {
    staticClass: "filter-select",
    attrs: {
      "item-text": "name",
      items: _vm.listItems,
      "return-object": "",
      "hide-selected": "",
      multiple: "",
      "small-chips": "",
      "hide-details": "",
    },
    on: { change: _vm.onSelected },
    scopedSlots: _vm._u([
      {
        key: "selection",
        fn: function ({ item, parent }) {
          return [
            _c(
              "div",
              { staticClass: "selected-item", class: item._id },
              [
                _vm._v(" " + _vm._s(item.name) + " "),
                _c(
                  "v-icon",
                  {
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return parent.selectItem(item)
                      },
                    },
                  },
                  [_vm._v(" $delete ")]
                ),
              ],
              1
            ),
          ]
        },
      },
      {
        key: "item",
        fn: function ({ item }) {
          return [
            _c("div", { staticClass: "list-item", class: item._id }, [
              _vm._v(" " + _vm._s(item.name) + " "),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v
      },
      expression: "selected",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }