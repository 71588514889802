var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-autocomplete", {
        attrs: {
          items: _vm.listItems,
          "return-object": "",
          "hide-selected": "",
          "item-text": "name",
          multiple: "",
          "small-chips": "",
          "deletable-chips": "",
        },
        on: { change: _vm.emitSelected },
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }