var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      staticClass: "app",
      class: { "px-0": _vm.$vuetify.breakpoint.xsOnly },
      style: { backgroundImage: "url(" + _vm.bg + ")" },
    },
    [
      _c(
        "v-main",
        { staticClass: "content" },
        [_c("toast-container"), _c("router-view")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }