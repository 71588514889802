var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c(
        "v-btn",
        {
          attrs: { color: "black", small: "", dark: "" },
          on: {
            click: function ($event) {
              return _vm.goToProfile()
            },
          },
        },
        [
          _vm.user.avatar
            ? _c(
                "v-avatar",
                {
                  staticClass: "mr-2",
                  attrs: { color: "grey lighten-4", size: "25" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: _vm.basicUrl + "/images/" + _vm.user.avatar,
                      alt: _vm.user.firstName,
                    },
                  }),
                ]
              )
            : _c("v-icon", { staticClass: "mr-2" }, [
                _vm._v("mdi-account-circle"),
              ]),
          _vm._v(" " + _vm._s(_vm.user.firstName) + " "),
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { color: "black", small: "", dark: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("logout")
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-exit-to-app")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }