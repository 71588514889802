var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        width: "200",
        persistent: "",
        "enable-resize-watcher": "",
        fixed: "",
        app: "",
      },
    },
    [
      _c(
        "v-toolbar",
        { staticClass: "transparent", attrs: { flat: "" } },
        [
          _c(
            "v-list",
            { staticClass: "py-3" },
            [
              _c(
                "v-list-item",
                { staticClass: "white--text mt-5" },
                [
                  _c("v-img", {
                    staticClass: "logo",
                    attrs: { cover: "", width: "150px", src: _vm.logoLang.src },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "v-list",
        { staticClass: "mt-10" },
        _vm._l(_vm.filteredRoutes, function (item, i) {
          return _vm.hideItem(item.id)
            ? _c(
                "v-list-item",
                {
                  key: i,
                  staticClass: "tile",
                  class: { tile: _vm.mini },
                  attrs: {
                    value: item.path === _vm.route,
                    "active-class": "secondary--text active-route",
                    to: item.path === "#" ? "" : item.path,
                  },
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c("v-icon", {
                        staticClass: "icon",
                        attrs: {
                          color: item.path === _vm.route ? "secondary" : "",
                        },
                        domProps: { innerHTML: _vm._s(item.icon) },
                      }),
                    ],
                    1
                  ),
                  _c("v-list-item-title", {
                    staticClass: "white--text",
                    domProps: { textContent: _vm._s(item.title) },
                  }),
                ],
                1
              )
            : _vm._e()
        }),
        1
      ),
      _c("v-spacer", { staticClass: "spacer" }),
      _c("v-list"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }