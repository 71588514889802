var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      attrs: { id: "json-to-csv-" + _vm._uid },
      on: { click: _vm.handleClick },
    },
    [
      _vm._t("default", function () {
        return [_c("button", [_vm._v(" Download ")])]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }