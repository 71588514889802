var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    staticClass: "search",
    attrs: {
      "append-icon": "mdi-magnify",
      label: _vm.label || "Search",
      "single-line": "",
      filled: "",
      "hide-details": "",
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v
      },
      expression: "search",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }